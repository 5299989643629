@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  img {
    @apply inline-block;
  }
}
body {
  background-color: white;
}

input {
  padding: 0;
  margin: 0;
  vertical-align: baseline;
}